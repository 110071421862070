<template>
  <div ref="print">
    <a-row>
      <a-col :span="6">
        <a-descriptions
          :column="1"
          class="custom-descriptions custom-descriptions-max-7"
        >
          <a-descriptions-item label="订单号">
            <span>{{ order.order_no }}</span>
          </a-descriptions-item>
          <a-descriptions-item label="订单类型">
            <span>{{ order.record_type_display }}</span>
          </a-descriptions-item>
          <a-descriptions-item label="订单来源">
            {{ order.source }}
          </a-descriptions-item>
        </a-descriptions>
      </a-col>
      <a-col :span="6">
        <a-descriptions
          :column="1"
          class="custom-descriptions custom-descriptions-max-7"
        >
          <a-descriptions-item label="负责人">
            {{ order.head_name }}
          </a-descriptions-item>
          <a-descriptions-item label="创建时间">
            {{ order.created_at }}
          </a-descriptions-item>
        </a-descriptions>
      </a-col>
      <a-col :span="6">
        <a-descriptions
          :column="1"
          class="custom-descriptions custom-descriptions-max-7"
        >
          <a-descriptions-item label="申请人">
            {{ order.apply_name }}
          </a-descriptions-item>
          <a-descriptions-item label="联系电话">
            {{ order.phone_number }}
          </a-descriptions-item>
        </a-descriptions>
      </a-col>
      <a-col :span="6">
        <a-descriptions
          :column="1"
          class="custom-descriptions custom-descriptions-max-7"
        >
          <a-descriptions-item label="丧属">
            {{ order.masterName }}
          </a-descriptions-item>
          <a-descriptions-item label="联系电话">
            {{ order.masterPhone }}
          </a-descriptions-item>
        </a-descriptions>
      </a-col>
    </a-row>
    <a-divider />
    <a-row>
      <a-col :span="8">
        <a-descriptions
          :column="1"
          class="custom-descriptions custom-descriptions-max-7"
        >
          <a-descriptions-item label="逝者姓名">
            <span>{{ order.death_name }}</span>
          </a-descriptions-item>
        </a-descriptions>
      </a-col>

      <a-col :span="8">
        <a-descriptions
          :column="1"
          class="custom-descriptions custom-descriptions-max-7"
        >
          <a-descriptions-item label="性别">
            {{ order.sex_display }}
          </a-descriptions-item>
        </a-descriptions>
      </a-col>
      <a-col :span="8">
        <a-descriptions
          :column="1"
          class="custom-descriptions custom-descriptions-max-7"
        >
          <a-descriptions-item label="年龄">
            {{ order.age }}
          </a-descriptions-item>
        </a-descriptions>
      </a-col>
    </a-row>
    <a-divider />
    <a-row>
      <a-col :span="8">
        <a-descriptions
          :column="1"
          class="custom-descriptions custom-descriptions-max-7"
        >
          <a-descriptions-item label="厅房">
            <span>{{ order.hall_name }}</span>
          </a-descriptions-item>
        </a-descriptions>
      </a-col>

      <a-col :span="8">
        <a-descriptions
          :column="1"
          class="custom-descriptions custom-descriptions-max-7"
        >
          <a-descriptions-item label="厅房价格">
            {{ parseFloat(order.hall_price).toFixed(2) }}
          </a-descriptions-item>
        </a-descriptions>
      </a-col>
      <a-col :span="8">
        <a-descriptions
          :column="1"
          class="custom-descriptions custom-descriptions-max-7"
        >
          <a-descriptions-item label="停棺时长">
            {{ order.package_time }}
          </a-descriptions-item>
        </a-descriptions>
      </a-col>
    </a-row>
    <a-divider />
    <a-row>
      <a-col :span="14">
        <a-descriptions
          :column="1"
          class="custom-descriptions custom-descriptions-max-10"
        >
          <a-descriptions-item label="套餐名称">{{ order.package_name }}</a-descriptions-item>
        </a-descriptions>
      </a-col>
      <a-col :span="4" :offset="1">
        <span>共{{ packageInfo.item_count }}项</span>
      </a-col>
      <a-col :span="4" :offset="1">
        <span style="color:green">实收金额：{{ parseFloat(order.package_fee - order.voucher_fee).toFixed(2) }}元</span>
      </a-col>
    </a-row>
    <a-table
      row-key="id"
      :columns="columns"
      :data-source="items"
      :pagination="false"
      @change="fetchData"
    />
    <a-divider />
    <a-row>
      <a-col :span="10">
        <a-descriptions
          :column="1"
          class="custom-descriptions custom-descriptions-max-10"
        >
          <a-descriptions-item label="其他项目" />
        </a-descriptions>
      </a-col>
      <a-col :span="4" :offset="1">
        <span>共{{ itemData.item_count }}项</span>
      </a-col>
      <a-col :span="4" :offset="1">
        <span style="color:green">小计：{{ itemData.subscribe_fee_display }}元</span>
      </a-col>
      <a-col :span="4">
        <span style="color:green">实收金额：{{ parseFloat(itemData.total_fee).toFixed(2) }}元</span>
      </a-col>
    </a-row>
    <a-table
      style="overflow: auto"
      size="middle"
      :columns="tableColumns"
      :data-source="itemData.product_list"
      :pagination="false"
      :expand-icon="expandIcon"
      row-key="id"
    >
      <a-table
        slot="expandedRowRender"
        slot-scope="record"
        :columns="childrenColumns"
        :data-source="record.service_order_other_product_items"
        :pagination="false"
        row-key="id"
      >
        <span slot="elegiac_information" slot-scope="text, record">
          <a-space>
            <a @click="showElegiacInformationModal(record)">点击查看</a>
          </a-space>
        </span>
      </a-table>
    </a-table>
    <a-divider />
    <a-row>
      <a-col :span="8" :offset="11">
        <a-descriptions
          :column="1"
          class="custom-descriptions custom-descriptions-max-10 termination-info"
        >
          <a-descriptions-item label="套餐金额">
            <span style="word-break:break-all">{{ order.package_fee }}元</span>
          </a-descriptions-item>
          <a-descriptions-item label="套餐优惠金额">
            <span style="word-break:break-all">{{ order.voucher_fee }}元</span>
            <a style="margin-left:20px;" @click="voucherShow()">详情</a>
          </a-descriptions-item>
          <a-descriptions-item label="套餐实收金额">
            <span style="word-break:break-all">{{ parseFloat(order.package_fee - order.voucher_fee).toFixed(2) }}元</span>
          </a-descriptions-item>
          <a-descriptions-item label="其他项目实收金额">
            <span style="word-break:break-all">{{ order.other_item_fee }}元</span>
          </a-descriptions-item>
          <a-descriptions-item label="厅房金额">
            <span style="word-break:break-all">{{ order.hall_fee }}元</span>
          </a-descriptions-item>
          <a-descriptions-item label="厅房使用金额">
            <span style="word-break:break-all">{{ order.hall_usage_fee }}元</span>
          </a-descriptions-item>
          <a-descriptions-item label="消毒金额">
            <span style="word-break: break-all">{{ order.disinfection_fee }}元</span>
          </a-descriptions-item>
          <a-descriptions-item label="佛事场地金额">
            <span style="word-break: break-all">{{ order.buddhist_venue_fee }}元</span>
          </a-descriptions-item>
          <a-descriptions-item label="结算金额">
            <span style="word-break:break-all">{{ order.settle_fee }}元</span>
            <span v-if="order.adjust" style="margin-left: 10px;color: red;">(调账)</span>
          </a-descriptions-item>
          <a-descriptions-item label="已实际支付金额">
            <span style="word-break:break-all">{{ order.paid_fee }}元</span>
            <a style="margin-left:20px;" @click="paidShow()">详情</a>
          </a-descriptions-item>

          <a-descriptions-item label="剩余应付金额">
            <span style="word-break:break-all">{{ order.unpaid_fee }}元</span>
          </a-descriptions-item>
          <a-descriptions-item label="应退金额">
            <span style="word-break:break-all">{{ order.withdrawn_fee }}元</span>
          </a-descriptions-item>
        </a-descriptions>
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="8" :offset="11" v-if="itemData.is_show_settle">
        <a-space :wrap="true" align="start">
          <a-button type="primary" v-if="orderType === 'billing'" @click="showUpdateOtherItemsModal">修改项目</a-button>
          <a-button type="primary" v-if="orderType === 'billing'" @click="showReconciliationModal">调账</a-button>
          <a-button type="primary" :loading="loadingPDF" @click="print">打印</a-button>
          <a-button type="primary" :loading="loadingUnpaidPDF" @click="printUnpaid">打印未支付结算单</a-button>
          <a-button type="primary" v-if="orderType === 'billing'" @click="showBillingOrderModal">结算订单</a-button>
          <a-button type="primary" v-if="orderType === 'billing'" @click="showSendPaymentReminderModal">发送结算提醒</a-button>
        </a-space>
      </a-col>
    </a-row>

    <!-- 修改项目模态框 -->
    <modify-other-item
      v-if="isShowUpdateOtherItemsModal"
      :visible.sync="isShowUpdateOtherItemsModal"
      :service-order-id="order.record_id"
      :id="orderId"
      @completed="fetchData"
    />
    <!-- 已实际支付金额详情模态框 -->
    <show-voucher
      v-if="isShowVoucher"
      :visible.sync="isShowVoucher"
      :order-id="orderId"
    />
    <!-- 优惠金额详情模态框 -->
    <show-paid
      v-if="isShowPaid"
      :visible.sync="isShowPaid"
      :order-id="orderId"
    />
    <!-- 调账模态框 -->
    <show-reconciliation
      v-if="isReconciliation"
      :visible.sync="isReconciliation"
      :id="orderId"
      :amount="oldSettlementAmount"
      @completed="fetchData"
    />
    <!-- 支付提醒模态框 -->
    <show-send-payment-reminder
      v-if="isSendPaymentReminder"
      :visible.sync="isSendPaymentReminder"
      :id="orderId"
      :unpaid-fee="unpaidFee"
    />
    <!-- 终止订单模态框 -->
    <show-termination-order
      v-if="isTerminationlOrder"
      :visible.sync="isTerminationlOrder"
      :id="orderId"
      :record="order"
      @completed="fetchData"
    />
    <!-- 结算订单模态框 -->
    <show-billing-order
      v-if="isBillingOrder"
      :visible.sync="isBillingOrder"
      :id="orderId"
      :record="order"
      :stop="isStop"
    />
  </div>
</template>

<script>
import {
  findOrderInfo,
  findOrderPackages,
  findItemAccountingSummary,
  createSettlementPDF,
  createUnpaidSettlementPDF
} from '@/api/order'
import printJS from 'print-js'

export default {
  name: 'BasicInformation',
  components: {
    ShowPaid: () => import('@/views/orders/tenant/ShowPaid'),
    ShowVoucher: () => import('@/views/orders/tenant/ShowVoucher'),
    ShowReconciliation: () => import('@/views/orders/tenant/operation_modal/Reconciliation'),
    ShowSendPaymentReminder: () => import('@/views/orders/tenant/operation_modal/SendPaymentReminder'),
    ShowTerminationOrder: () => import('@/views/orders/tenant/operation_modal/TerminationOrder'),
    ShowBillingOrder: () => import('@/views/orders/tenant/operation_modal/BillingOrder'),
    ModifyOtherItem: () => import('@/views/orders/tenant/operation_modal/ModifyOtherItem')
  },
  data() {
    return {
      order: {},
      data: {},
      itemData: {},
      tableData: [],
      packageInfo: {},
      items: [],
      unpaidFee: 0,
      oldSettlementAmount: 0,
      isShowVoucher: false,
      isShowPaid: false,
      isReconciliation: false,
      isSendPaymentReminder: false,
      isTerminationlOrder: false,
      isBillingOrder: false,
      isShowUpdateOtherItemsModal: false,
      loadingPDF: false,
      loadingUnpaidPDF: false,
      isStop: false
    }
  },
  computed: {
    orderId() {
      return parseInt(this.$route.params.id)
    },
    orderType() {
      return this.$route.query.order_type
    },
    columns() {
      return [
        {
          title: '项目名称',
          dataIndex: 'name'
        },
        {
          title: '类型',
          dataIndex: 'item_type'
        },
        {
          title: '计价单位',
          dataIndex: 'unit'
        },
        {
          title: '计价个数',
          dataIndex: 'count'
        },
        {
          title: '价格(元)',
          dataIndex: 'price_display'
        },
        {
          title: '项目介绍',
          dataIndex: 'remark'
        }
      ]
    },
    tableColumns() {
      return [
        {
          title: '序号',
          dataIndex: 'id'
        },
        {
          title: '品名',
          dataIndex: 'name'
        },
        {
          title: '类型',
          dataIndex: 'project_type_display'
        },
        {
          title: '数量',
          dataIndex: 'project_count'
        },
        {
          title: '小计(元)',
          dataIndex: 'subscribe_fee_display'
        },
        {
          title: '退货数量',
          dataIndex: 'back_count'
        },
        {
          title: '实收金额(元)',
          dataIndex: 'project_fee_display'
        }
      ]
    },
    childrenColumns() {
      return [
        {
          title: '规格',
          dataIndex: 'product_specification_name',
          ellipsis: true,
          width: 150
        },
        {
          title: '价格',
          dataIndex: 'price_display',
          ellipsis: true,
          width: 150
        },
        {
          title: '数量',
          dataIndex: 'count',
          ellipsis: true,
          width: 100
        },
        {
          title: '小计(元)',
          dataIndex: 'subscribe_fee_display',
          ellipsis: true,
          width: 100
        },
        {
          title: '退货数量',
          dataIndex: 'back_count',
          ellipsis: true,
          width: 100
        },
        {
          title: '项目编号',
          dataIndex: 'project_no',
          ellipsis: true,
          width: 180
        },
        {
          title: '实收金额(元)',
          dataIndex: 'fee',
          width: 150
        },
        {
          title: '订购时间',
          dataIndex: 'created_at',
          ellipsis: true,
          width: 180
        },
        {
          title: '备注',
          dataIndex: 'remark',
          ellipsis: true,
          width: 150
        }
      ]
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    expandIcon({ expanded, expandable, record, onExpand }) {
      if (!expandable) return null
      return (
        <a onClick={e => onExpand(record, e)}>
          {expanded ? <a-icon type='minus-square' /> : <a-icon type='plus-square' />}
        </a>
      )
    },
    showUpdateOtherItemsModal() {
      this.isShowUpdateOtherItemsModal = true
    },
    showSendPaymentReminderModal() {
      this.isSendPaymentReminder = true
      this.unpaidFee = parseFloat(this.order.unpaid_fee)
    },
    print() {
      this.$info({
        title: '正在生成结算清单PDF文件，请耐心等候',
        content: ''
      })
      if (this.loadingPDF) {
        return
      }

      this.loadingPDF = true
      createSettlementPDF(this.orderId).then((res) => {
        if (res.code === 0) {
          if (res.data.url) {
            printJS(res.data.url)
          }
        }
      })
      this.loadingPDF = false
    },
    printUnpaid() {
      this.$info({
        title: '正在生成未支付结算清单PDF文件，请耐心等候',
        content: ''
      })
      if (this.loadingUnpaidPDF) {
        return
      }

      this.loadingUnpaidPDF = true
      createUnpaidSettlementPDF(this.orderId).then((res) => {
        if (res.code === 0) {
          if (res.data.url) {
            printJS(res.data.url)
          }
        }
      })
      this.loadingUnpaidPDF = false
    },

    showReconciliationModal() {
      this.isReconciliation = true
      this.oldSettlementAmount = this.order.settle_fee
    },
    showBillingOrderModal() {
      this.isBillingOrder = true
      this.isStop = this.order.stopped
    },
    paidShow() {
      this.isShowPaid = true
    },
    voucherShow() {
      this.isShowVoucher = true
    },
    fetchData() {
      findItemAccountingSummary(this.orderId).then((res) => {
        this.itemData = res.data

        if (this.itemData.is_show_settle) {
          findOrderInfo(this.orderId).then((res) => {
            this.order = res.data
          })
          findOrderPackages(this.orderId).then(res => {
            if (res.code === 0) {
              this.packageInfo = res.data
              this.items = res.data.item_list
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.custom-blue {
  cursor: pointer;
}

/deep/
.ant-descriptions-item {
  display: flex;

  .ant-descriptions-item-content {
    display: flex;
  }

  .ant-descriptions-item span {
    display: table-cell;
  }
}
</style>
